<template>
    <!-- 已选 -->
    <div class="select box">
        <!-- <select-title title="已选择"></select-title> -->
        <div class="select-title-wrapper">
            <select-title title="已选择"></select-title>
            <div class="select-title-btn" @click="openGroupDialog" v-if="isGroup">
                <!-- <i class="el-icon-folder-checked"></i> -->
                <img src="@/assets/images/save-group.png">
                <span>存为分组</span>
            </div>
        </div>
        <div :class="[addMemberSetting==1 ? 'select-wrapper top' : 'select-wrapper']">
            <!-- 已选择人员 -->
            <div
                v-for="(item, index) of selectTreeNodeData"
                :key="index"
                :class="[item.id==currentSelectId ? 'select-item active' : 'select-item']"
            >
                <div class="select-item-content" @click="handlerSelectTreeNode($event, item)">
                    <img v-if="['student', 'teacher'].includes(item.type)||item.name" :src="personnelImg" alt="personnel">
                    <img v-else :src="departmentImg" alt="department">
                    <span class="title">{{ item.name||item.teacherName }}</span>
                </div>
                <span class="del-wrapper" @click="handlerDelTreeNode($event, item)">
                    <img :src="delImg" alt="del">
                </span>
            </div>
        </div>
        <!-- 参会人班级 -->
        <div class="class-wrapper" v-if="addMemberSetting==1 && currentSelectId">
            <div class="title">
                <div class="left">
                    选择班级
                    <el-tooltip class="item" effect="dark" content="职工档案中（任教班级+负责班级+班级权限）的并集" placement="top">
                        <i class="el-icon-question"></i>
                    </el-tooltip>
                </div>
                <div class="right" v-if="classList.length>0">
                    <el-checkbox 
                        :indeterminate="checkClassIndeterminate" 
                        v-model="checkAllClass" 
                        @change="handleCheckAllChange"
                    >全选</el-checkbox>
                </div>
            </div>
            <div class="class-list">
                <el-checkbox-group 
                    v-if="classList.length>0"
                    v-model="checkedClass" 
                    @change="handleCheckedClassChange"
                >
                    <el-checkbox 
                        v-for="(item, index) in classList" 
                        :label="item.organId" 
                        :key="'class-'+index"
                    >
                        <span class="organName">{{item.organName}}</span>
                        <el-tooltip class="item" effect="dark" :content="item.parentOrg + '/' + item.organName" placement="top">
                            <i class="el-icon-question"></i>
                        </el-tooltip>
                    </el-checkbox>
                </el-checkbox-group>
                <div v-else class="class-empty">
                    暂无数据
                </div>
            </div>
        </div>
        <!-- 存为分组弹窗 -->
        <el-dialog append-to-body class="add-group" :modal-append-to-body="true" :visible.sync="dialogObj.dialogVisible"  :title="dialogObj.title" :width="dialogObj.width"  @handleClose="handleClose">
            <div class="tip">
                <i class="el-icon-warning-outline"></i>
                <span>将已选用户保存分组，下次可直接选用，只对当前用户生效</span>
            </div>
            <div class="form-wrapper">
                <div class="form-label">请输入分组名称</div>
                <el-input v-model="form.name" maxlength="15"
                    show-word-limit placeholder="请输入分组名称"></el-input>
            </div>
            <div style="text-align: right;">
                <el-button type="primary" @click="saveGroup">保存分组</el-button>
            </div>
            <!-- <el-form ref="form" label-position="top" :model="form" label-width="80px">
                <el-form-item label="分组名称">

                </el-form-item>
            </el-form> -->
        </el-dialog>
    </div>
</template>
<script>
import SelectTitle from "../../components/Title/SelectTitle.vue";
export default {
    name: "TreeShapeSelected",
    components: {
        SelectTitle
    },
    data () {
        return {
            openGroup: false,
            dialogObj:{
                title: '存为分组',
                dialogVisible: false,
                width: '500px'
            },
            form:{
                name:''
            },
            currentSelectId: '', // 当前选中的节点id
            checkAllClass: false, // 是否全选
            checkClassIndeterminate: false, // 是否半选
            checkedClass: [], // 已选中的班级
            classList: [], // 班级列表
        }
    },
    props: {
        selectTreeNodeData: {
            type: Array,
            default: () => {
                return []
            }
        },
        isGroup: {
            type: Boolean,
            default: false
        },
        addMemberSetting: {
            type: String,
            default: '0'
        }
    },
    computed: {
        departmentImg () {
            return require('../../../img/department.png')
        },
        personnelImg () {
            return require('../../../img/personnel.png')
        },
        delImg () {
            return require('../../../img/del.png')
        }
    },
    created () {
        console.log('选择参会人了')
    },
    methods: {
        async handlerSelectTreeNode(e, item) {
            if(this.addMemberSetting!=1) return
            console.log('选中参会人', e, item)
            // 获取参会人的所属班级
            this.currentSelectId = item.id;
            this.classList = item.classList ? item.classList : await this.getClassList(item);
            // 如果班级列表只有一个，则默认勾选
            if(this.classList.length && this.classList.length == 1) {
                this.$set(item, 'classIds', this.classList[0].organId);
            }
            this.$set(item, 'classList', this.classList);
            this.$nextTick(() => {
                console.log('item-------', item)
                this.checkedClass = item.classIds ? item.classIds.split(',') : [];
                this.checkAllClass = this.checkedClass.length == item.classList.length;
                this.checkClassIndeterminate = this.checkedClass.length > 0 && this.checkedClass.length < item.classList.length;
            })
        },
        /**
         * @Description: 班级列表全选
         * @DoWhat: 
         * @UseScenarios: 
         * @Attention: 
         * @Author: 王妙田
         * @Date: 2025-01-21 08:50:03
         * @param {*} val
         */        
        handleCheckAllChange(val) {
            this.checkedClass = val ? this.classList.map(item => item.organId)  : [];
            this.checkClassIndeterminate = false;
            let item = this.selectTreeNodeData.find(i=>i.id==this.currentSelectId);
            item.classIds = this.checkedClass.join(',');
        },
        /**
         * @Description: 班级勾选监听
         * @DoWhat: 
         * @UseScenarios: 
         * @Attention: 
         * @Author: 王妙田
         * @Date: 2025-01-21 08:54:33
         * @param {*} val
         */        
        handleCheckedClassChange(val) {
            console.log('班级勾选', val)
            let checkedCount = val.length;
            this.checkAllClass = checkedCount === this.classList.length;
            this.checkClassIndeterminate = checkedCount > 0 && checkedCount < this.classList.length;
            let item = this.selectTreeNodeData.find(i=>i.id==this.currentSelectId);
            item.classIds = this.checkedClass.join(',');
            console.log('班级勾选', item)
        },
        handlerDelTreeNode(e, item) {
            this.$emit('handlerDelTreeNode', {e: e, item: item})
        },
        openGroupDialog(){
            if(this.selectTreeNodeData.length == 0){
                this.$message.warning('请先选择分组成员')
                return
            }
            this.dialogObj.dialogVisible = true
            this.form.name = ""
        },
        handleClose(){
            this.dialogObj.dialogVisible = false
        },
        /**
         * @Description: 保存分组
         * @Author: 王梦瑶
         * @Date: 2024-09-19 14:30:07
         */
        saveGroup(){

            if(this.form.name){
                let query = {
                    name:this.form.name,
                    selectTreeNodeData:this.selectTreeNodeData,
                }
                this.$emit('saveGroup',query)
                this.dialogObj.dialogVisible = false
            } else {
                this.$message.warning('请输入分组名称')
            }

        },
        /**
         * @Description: 获取选中人员的所属班级
         * @DoWhat: 
         * @UseScenarios: 
         * @Attention: 
         * @Author: 王妙田
         * @Date: 2025-01-21 08:49:26
         * @param {*} item
         */        
        getClassList(item) {
            return new Promise((resolve, reject) => {
                let obj = {
                    schoolId: this.$store.state.schoolId,
                    teacherId: item.id,
                    organType: '4',
                    jobScope: '1,2,3',
                }
                this._fet("/school/schoolTeacher/getTeaOrganById", obj)
                .then((res) => {
                    console.log('班级列表', res)
                    if (res.data.code === "200") {
                        // this.classList = res.data.data;
                        resolve(res.data.data)
                        
                    } else {
                        resolve([])
                    }
                })
                .catch((err) => {
                    console.log(err)
                    resolve([])
                })
            })
        },
    },
};
</script>
<style lang="scss" scoped>
.box {
    width: 350px;
    box-sizing: border-box;
    padding: 0 0 0 24px;
}
.select {
    border-left: 1px solid #ddd;
    .select-wrapper {
        width: 100%;
        height: 380px;
        overflow: auto;

        &.top {
            height: 250px;

            .select-item {
                .select-item-content {
                    cursor: pointer;
                }

                &.active {
                    border-color: #3C7FFF;
                }
            }
        }
        .select-item {
            width: 140px;
            height: 32px;
            margin: 0px 8px 8px 0px;
            box-sizing: border-box;
            padding: 4px 8px 4px 4px;
            font-size: 14px;
            background-color: #F5F5F5;
            border-radius: 3px;
            color: #333;
            display: flex;
            justify-content: space-between;
            align-items: center;
            float: left;
            border: 1px solid transparent;
            .select-item-content {
                display: flex;
                align-items: center;
                .title {
                    width: 80px;
                    margin-left: 10px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
            .del-wrapper {
                width: 8px;
                height: 8px;
                cursor: pointer;
                display: inline-flex;
                align-items: center;
            }
        }
    }

    .class-wrapper {
        padding: 10px 0;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: calc(100% - 14px);
            height: 1px;
            background-color: #ddd;
        }

        .title {
            color: #9DA2A6;
            // display: flex;
            align-items: center;
            justify-content: space-between;
            padding-right: 20px;
        }
        .class-list {
            height: 92px;
            overflow: auto;
            .el-checkbox-group {
                
                .el-checkbox {
                    margin-top: 4px;
                    margin-right: 10px;
                    width: calc(50% - 10px);

                    ::v-deep .el-checkbox__label {
                        width: calc(100% - 30px);
                        line-height: 14px;
                        vertical-align: text-bottom;
                    }
                    .organName {
                        display: inline-block;
                        max-width: 110px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }
            .class-empty {
                color: #9DA2A6;
                text-align: center;
                padding: 36px 0;
            }
        }
    }
}
.select-title-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
    .select-title-btn{
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #3C7FFF;
        cursor: pointer;
        display: flex;
        align-items: center;
        img{
            width: 19px;
            height: 15px;
            margin-right: 8px;
        }
    }
}
.tip{
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 12px;
    color: #6D6F73;
    i{
        margin-right: 5px;
    }
}
.form-wrapper{
    margin-bottom: 30px;
    .form-label{
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #2B2E33;
        margin-bottom: 10px;
        margin-top: 11px;
    }
}
</style>
<style lang="scss">
.add-group{
    .el-dialog__body{
        padding-top: 0px !important;
    }
}
</style>
